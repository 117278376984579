.navbarContainer {
  background-color: #06181e;
  padding: 10px 5%;
}

.hamburger-button {
  width: 10%;
  color: #f2efeb;
  margin-left: 15px;
}

.hamburger-button:hover {
  cursor: pointer;
}

.navbar-logo {
  text-align: center;
  /* transform: rotate(0deg);
  transition: transform 2s; */
}

/* .navbar-logo:hover {
  transform: rotate(20deg);
  transition: transform 2s;
} */

/* .navbar-logo-large {
  display: none;
} */

@media (min-width: 900px) {
  .hamburger-button {
    display: none;
  }

  .navbar-logo {
    padding-bottom: 19px;
  }
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  padding: 5%;
  margin-top: 10px;
}

.card-wrapper img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: 50% 50%;
}

.hover-blurb {
  display: none;
}

.card-info > * {
  color: #06181e;
  margin: 0.5rem 0;
}

.card-info h2 {
  color: #06181e;
  font-weight: 100;
}

.card-info a {
  text-decoration: none;
}

.card-info span {
  font-weight: bold;
  font-size: 0.75rem;
}

.card-line-break {
  width: 100%;
  border-bottom: 6px solid var(--darkegg);
  margin-top: 10px;
}

.card-title {
  font-family: "Shrikhand", cursive;
}

.card-title a {
  color: #06181e;
}

.card-category a {
  color: var(--orange);
}

.card-category a:hover {
  color: var(--blue);
}

.read-more {
  float: left;
}

.read-more > span {
  color: #06181e;
  transition: color 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.read-more:hover > span {
  color: var(--orange);
}

.read-more #read-more-arrow {
  position: relative;
  left: 0;
  transition: left 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.read-more:hover #read-more-arrow {
  position: relative;
  left: 3px;
}

@media (min-width: 900px) {
  .card-wrapper {
    width: 30%;
    padding: 0;
  }
  .card-wrapper-sans-linebreak {
    /* border: 1px solid red; */
    /* min-height: 65vh; */
  }
  .hover-blurb {
    color: var(--orange);
    font-size: 1vw;
    font-weight: bold;
    display: inline;
    opacity: 0;
    position: absolute;
    top: 20%;
    left: 20%;
    transform: translate(-20%, -20%);
    transition: opacity 0.25s ease-in-out;
  }

  .post-card-image-link:hover .hover-blurb {
    opacity: 1;
  }

  .post-card-image-link img {
    filter: brightness(100%);
    transition: filter 0.25s ease-in-out;
  }

  .post-card-image-link:hover img {
    filter: brightness(30%);
  }

  .card-info {
    min-height: 17vh;
    /* min-height: 34vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .card-info > * {
    margin: 0.25rem 0;
  }
  .card-info p {
    display: none;
  }
}

.loader-wrapper {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  width: auto;
  background-color: var(--egg1);
}

.loader-wrapper span {
  color: var(--blue);
  margin-top: 10px;
}

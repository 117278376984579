@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Shrikhand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Shrikhand&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @keyframes animatedBackground {
  from {
    background-position: 100% 0;
  }
  to {
    background-position: 0 0;
  }
} */

.intro-container {
  /* border-top: 1px solid #06181e;
  background-image: url("../img/earf.png");
  min-height: 25vh; */
  /* background-attachment: fixed;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: contain; */
  /* text-align: top center; */
  /* margin: auto;
  width: 100vw; */
}

.home-container {
  background-color: var(--egg1);
}

.intro-paragraph {
  width: 100%;
  max-width: 100vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0 0;
}

.intro-paragraph p {
  /* padding: 20px 5% 0; */
  color: #06181e;
  padding: 0 5%;
}

.mission-statement {
  font-family: "Shrikhand", cursive;
  font-weight: lighter;
  color: var(--orange);
}

h1 {
  color: #06181e;
  text-align: center;
  font-weight: 100;
}

.recent-posts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recent-posts h1 {
  font-family: "Shrikhand", cursive;
}

.display-more-posts {
  text-align: center;
}

.display-more-posts span {
  font-size: 0.75rem;
  color: #06181e;
}

.display-more-posts span:hover {
  cursor: pointer;
  color: var(--orange);
}

.line-break {
  width: 90%;
  border-bottom: 6px solid var(--darkegg);
  margin-top: 10px;
}

.about-container {
  padding: 5%;
}

.about-container .line-break {
  width: 100%;
}

.about-container h1 {
  font-family: "Shrikhand", cursive;
}

.about-container img {
  width: 100%;
  height: auto;
}

.about-container p {
  text-align: center;
}

.insta-section {
  padding: 0 5%;
}

.insta-section h1 {
  font-family: "Shrikhand", cursive;
}

.insta-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.insta-title-container .line-break {
  width: 88vw;
  margin-bottom: 10px;
}

.picture-section {
  padding: 7px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.picture-section img {
  object-fit: cover;
  object-position: 50% 50%;
  width: 88vw;
  height: auto;
  max-height: 88vw;
  /* margin: 2% 0; */
  padding: 2% 0;
  /* box-shadow: 0 0 2px var(--blue); */
}

.box {
  width: 300px;
  height: 300px;
  /* padding-top: 300px; */
  background-color: black;
  margin-bottom: 7px;
}

/* ADMIN STYLES */

.admin-button {
  border: 1px solid var(--blue);
  background-color: white;
  color: var(--blue);
  width: 150px;
  height: 50px;
}

.admin-button:hover {
  background-color: var(--blue);
  color: white;
  cursor: pointer;
}

.admin-title {
  margin: 0;
  padding-top: 20px;
}

.admin-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.admin-buttons button {
  margin-bottom: 10px;
}

.home-container-desktop {
  display: none;
}

.about-contact-desktop {
  display: none;
}

.contact-form-error {
  color: red;
  font-size: 0.8rem;
  font-weight: bold;
}
.contact-form-submitted {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 59vh;
}
.contact-form-sending {
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 10px;
  line-height: 3.1rem;
  font-size: 1rem;
}
.contact-form-thank {
  color: var(--egg1);
}

.contact-form-thank-message {
  color: var(--egg1);
  font-size: 0.9rem;
}
@media (min-width: 900px) {
  /* .home-container-mobile {
    display: none;
  }

  .home-container-desktop {
    display: flex;
    padding: 0 5vw;
  }

  .right-panel {
    width: 85%;
    border-left: 1px solid var(--blue);
  } */
  .home-container-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .intro-paragraph p {
    /* padding: 20px 5% 0; */
    color: #06181e;
    padding: 0 5%;
  }
  .short-intro {
    font-size: 1.1rem;
  }

  .mission-statement {
    font-family: "Shrikhand", cursive;
    font-weight: lighter;
    color: var(--orange);
    font-size: 1.2rem;
  }
  .recent-posts {
    margin-top: 5vh;
  }
  .line-break {
    width: 100%;
  }
  .home-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 3% 5% 5%;
  }
  .about-container {
    display: none;
  }
  .insta-section {
    display: flex;
    padding: 5% 5% 5% 5%;
  }

  .insta-content {
    /* width: 67%; */
    text-align: center;
  }
  .insta-title-container {
    display: inline-block;
    margin-bottom: 5%;
  }
  .insta-title-container .line-break {
    width: 100%;
  }
  .picture-section {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-content: space-between;
  }
  .picture-section img {
    object-fit: cover;
    width: 20vw;
    height: 20vw;
    margin: 1% 0;
  }
  .about-author-container .line-break {
    margin-bottom: 45px;
  }
  .about-contact-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 40px;
    padding: 0 5%;
  }
  .about-container-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 75%; */
    /* margin: 0 5%; */
  }

  .about-container-desktop h1 {
    color: var(--blue);
    font-family: "Shrikhand", cursive;
  }
  .about-desktop-content {
    display: flex;
  }
  .about-container-desktop img {
    /* width: 70%;
    margin-top: 7%; */
    margin: 0 7px 7px 0;
    object-fit: cover;
    object-position: 50% 50%;
    height: 75vh;
    width: 75vh;
  }
  .about-desktop-paragraphs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.2rem;
    padding: 3%;
  }
  .about-desktop-paragraphs p {
    color: var(--blue);
  }
  .contact-section {
    width: 60%;
    margin: 0;
    padding: 5% 5%;
    background-color: var(--egg1);
    text-align: center;
  }
  .contact-section .contact-me-container {
    display: inline-block;
  }
  .contact-section h1 {
    color: var(--blue);
    font-family: "Shrikhand", cursive;
  }
  .contact-section form {
    margin: 7% 0 0 0;
    padding: 0 5%;
    background-color: var(--blue);
    color: var(--orange);
    text-align: left;
    height: 65vh;
    display: flex;
    justify-content: center;
  }
  .contact-section label {
    font-weight: bold;
  }
  .contact-section input {
    border: 1px solid var(--blue);
    line-height: 2;
    background: var(--egg1);
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    color: var(--blue);
  }
  .contact-section input:focus,
  .contact-section textarea:focus {
    outline: none !important;
    border-color: var(--orange);
    box-shadow: 0 0 8px var(--orange);
  }
  .contact-section input:hover,
  .contact-section textarea:hover {
    outline: none !important;
    border-color: var(--orange);
  }
  .contact-section textarea {
    border: 1px solid var(--blue);
    height: 150px;
    resize: none;
    background: var(--egg1);
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    color: var(--blue);
  }
  .contact-section button {
    margin-top: 50px;
    margin-bottom: 50px;
    border-color: var(--orange);
    color: var(--orange);
    font-weight: bold;
  }
  .contact-section button:hover {
    /* border-color: var(--blue); */
    color: var(--blue);
    background-color: var(--orange);
    box-shadow: 0 0 10px var(--orange);
  }
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  padding: 5%;
  margin-top: 10px;
}

.card-wrapper img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: 50% 50%;
}

.hover-blurb {
  display: none;
}

.card-info > * {
  color: #06181e;
  margin: 0.5rem 0;
}

.card-info h2 {
  color: #06181e;
  font-weight: 100;
}

.card-info a {
  text-decoration: none;
}

.card-info span {
  font-weight: bold;
  font-size: 0.75rem;
}

.card-line-break {
  width: 100%;
  border-bottom: 6px solid var(--darkegg);
  margin-top: 10px;
}

.card-title {
  font-family: "Shrikhand", cursive;
}

.card-title a {
  color: #06181e;
}

.card-category a {
  color: var(--orange);
}

.card-category a:hover {
  color: var(--blue);
}

.read-more {
  float: left;
}

.read-more > span {
  color: #06181e;
  transition: color 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.read-more:hover > span {
  color: var(--orange);
}

.read-more #read-more-arrow {
  position: relative;
  left: 0;
  transition: left 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.read-more:hover #read-more-arrow {
  position: relative;
  left: 3px;
}

@media (min-width: 900px) {
  .card-wrapper {
    width: 30%;
    padding: 0;
  }
  .card-wrapper-sans-linebreak {
    /* border: 1px solid red; */
    /* min-height: 65vh; */
  }
  .hover-blurb {
    color: var(--orange);
    font-size: 1vw;
    font-weight: bold;
    display: inline;
    opacity: 0;
    position: absolute;
    top: 20%;
    left: 20%;
    transform: translate(-20%, -20%);
    transition: opacity 0.25s ease-in-out;
  }

  .post-card-image-link:hover .hover-blurb {
    opacity: 1;
  }

  .post-card-image-link img {
    -webkit-filter: brightness(100%);
            filter: brightness(100%);
    transition: -webkit-filter 0.25s ease-in-out;
    transition: filter 0.25s ease-in-out;
    transition: filter 0.25s ease-in-out, -webkit-filter 0.25s ease-in-out;
  }

  .post-card-image-link:hover img {
    -webkit-filter: brightness(30%);
            filter: brightness(30%);
  }

  .card-info {
    min-height: 17vh;
    /* min-height: 34vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .card-info > * {
    margin: 0.25rem 0;
  }
  .card-info p {
    display: none;
  }
}

#login-button {
  border: 1px solid var(--blue);
  background-color: white;
  color: var(--blue);
  width: 150px;
  height: 50px;
}

#login-button:hover {
  background-color: var(--blue);
  color: white;
  cursor: pointer;
}

#logout-button {
  border: 1px solid var(--orange);
  background-color: var(--blue);
  color: var(--orange);
  width: 150px;
  height: 50px;
}

#logout-button:hover {
  background-color: var(--orange);
  border: 1px solid var(--blue);
  color: var(--blue);
  cursor: pointer;
}

.admin-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
}

.admin-info-container span {
  color: red;
  font-size: 0.75rem;
}

.admin-info-container button {
  width: 200px;
}

.admin-info-container button:hover {
  cursor: pointer;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
}

.login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 30%;
  background-color: var(--egg1);
  padding: 30px 0;
}

.mini-log-out {
  font-size: 0.75em;
  margin-top: 50px;
}

.mini-log-out:hover {
  cursor: pointer;
  color: red;
}

.category-page {
  background-color: var(--egg1);
  padding-bottom: 20px;
}

.category-header {
  padding: 5% 5% 2% 5%;
}

.category-title {
  font-family: "Shrikhand", cursive;
}

.category-line-break {
  border-bottom: 6px solid var(--darkegg);
  margin-top: 10px;
}

@media (min-width: 900px) {
  .category-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5%;
  }
}

.contact-section-mobile {
  /* margin: 0 0 15% 0; */
  padding: 0 5% 15%;
  text-align: center;
  background: var(--egg1);
}
.contact-section-mobile .contact-me-container {
  display: inline-block;
}
.contact-section-mobile h1 {
  color: var(--blue);
  font-family: "Shrikhand", cursive;
}
.contact-section-mobile form {
  /* margin: 0;
  padding: 0 5%; */
  margin: 7% 0 0 0;
  padding: 0 5%;
  background-color: var(--blue);
  color: var(--orange);
  text-align: left;
  /* background-color: var(--orange); */
}
.contact-section-mobile input {
  border: 1px solid var(--blue);
  line-height: 2;
  background: var(--egg1);
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: var(--blue);
}
.contact-section-mobile label {
  font-weight: bold;
}
.contact-section-mobile input:focus,
.contact-section-mobile textarea:focus {
  outline: none !important;
  border-color: var(--orange);
  box-shadow: 0 0 8px var(--orange);
}
.contact-section-mobile input:hover,
.contact-section-mobile textarea:hover {
  outline: none !important;
  border-color: var(--orange);
}
.contact-section-mobile textarea {
  border: 1px solid var(--blue);
  height: 150px;
  resize: none;
  background: var(--egg1);
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: var(--blue);
}
.contact-section-mobile button {
  margin-top: 50px;
  margin-bottom: 50px;
  border-color: var(--orange);
  color: var(--orange);
  font-weight: bold;
}
.contact-section-mobile button:hover {
  color: var(--blue);
  background-color: var(--orange);
  box-shadow: 0 0 10px var(--orange);
}

@media (min-width: 900px) {
  .contact-section-mobile form {
    max-width: 50%;
    margin: auto;
  }
}

.navbarContainer {
  background-color: #06181e;
  padding: 10px 5%;
}

.hamburger-button {
  width: 10%;
  color: #f2efeb;
  margin-left: 15px;
}

.hamburger-button:hover {
  cursor: pointer;
}

.navbar-logo {
  text-align: center;
  /* transform: rotate(0deg);
  transition: transform 2s; */
}

/* .navbar-logo:hover {
  transform: rotate(20deg);
  transition: transform 2s;
} */

/* .navbar-logo-large {
  display: none;
} */

@media (min-width: 900px) {
  .hamburger-button {
    display: none;
  }

  .navbar-logo {
    padding-bottom: 19px;
  }
}

.nav-buttons-desktop {
  display: flex;
  justify-content: space-between;
  /* padding-bottom: 50px; */
}

.nav-buttons-desktop * a {
  color: var(--egg1);
  text-decoration: none;
  font-family: "Shrikhand", cursive;
  /* font-size: 1.2rem; */
}

.nav-buttons-desktop * a:hover {
  color: var(--orange);
}

.desktop-category-buttons a {
  margin-left: 50px;
}

@media (max-width: 899px) {
  .nav-buttons-desktop {
    display: none;
  }
}

.navmenuContainer {
  background-color: #06181e;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  /* transition: transform 0.6s cubic-bezier(0.52, 0, 0, 1); */
  overflow: scroll;
  z-index: 1000;
  justify-content: center;
  overflow: hidden;
}

.page-links {
  display: flex;
  flex-direction: column;
  height: 50%;
  padding: 5%;
}

.navmenuContainer.hiding {
  /* transition: opacity 1s ease-out; */
  opacity: 0;
  transform: translate3d(-100vw, 0, 0);
  transition: opacity 0.2s ease-out, transform 0s 0.2s;
}

.navmenuContainer.showing {
  /* opacity: 1; */
  transform: translate3d(0vw, 0, 0);
  transition: transform 0.6s cubic-bezier(0.52, 0, 0, 1);
  overflow: hidden;
}

.closeButton {
  position: fixed;
  top: 0;
  right: 0;
  color: #f2efeb;
  margin: 10px 20px;
}

.closeButton:hover {
  cursor: pointer;
}

.navmenuContainer a {
  color: #f2efeb;
  text-decoration: none;
  font-size: 2rem;
  margin: 0.2rem 0;
}

.active {
  color: var(--orange);
}

.hidden {
  display: none;
}

.nav-menu-item:hover {
  color: var(--orange);
}

@media (min-width: 900px) {
  .navmenuContainer {
    display: none;
  }
}

.scroll-arrow-container {
  background-color: var(--egg1);
  padding-right: 5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.arrow-and-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.scroll-up-arrow {
  color: var(--blue);
  background-color: var(--egg1);
  position: relative;
  right: 35px;
}

.scroll-up-text {
  color: var(--blue);
  position: relative;
  right: 3px;
  bottom: 10px;
}

.arrow-and-text-container:hover {
  cursor: pointer;
}

.arrow-and-text-container:hover * {
  color: var(--orange);
  cursor: pointer;
}

.footer-container {
  background-color: #06181e;
  padding: 10px;
  min-height: 9vh;
}

.footer-content h4 {
  margin-bottom: 15px;
  color: var(--egg1);
}

.footer-text {
  color: var(--egg1);
  text-align: center;
  margin: 0;
}

.footer-link {
  color: var(--orange);
  text-decoration: none;
  text-align: center;
  margin: 0;
}

.footer-link:hover {
  color: var(--orange);
  text-decoration: underline;
  text-align: center;
  margin: 0;
}

.footer-interactive {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-form {
  display: flex;
  flex-direction: column;
  width: 270px;
}

.footer-form input {
  text-align: center;
  margin-bottom: 15px;
}

.footer-form button {
  background-color: #06181e;
  border: 1px solid white;
  color: white;
  margin-bottom: 15px;
}

.footer-form button:hover {
  background-color: white;
  border: 1px solid #06181e;
  color: #06181e;
  margin-bottom: 15px;
}

.footer-social-icons {
  min-width: 270px;
  max-width: 100vw;
  color: var(--egg1);
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.footer-copyright {
  margin-top: 4vh;
}

.footer-copyright span {
  display: block;
  font-size: 12px;
  color: var(--egg1);
}

.social-icon {
  width: 10%;
}

.blog-post-container {
  background-color: var(--egg1);
}

.blog-post-container img {
  width: 100%;
  height: auto;
}

.blog-post-container h1 {
  margin: 0;
  padding: 30px 10px;
  font-family: "Shrikhand", cursive;
}

.blog-post-container h5 {
  padding: 0 10px;
  color: var(--blue);
  font-family: "Shrikhand", cursive;
  font-weight: 100;
}

.blog-post-container a {
  text-decoration: none;
  color: var(--blue);
}

.blog-post-container a:hover {
  color: var(--orange);
}

.blog-post-container .post-data {
  padding: 0 10px;
  padding-bottom: 30px;
  color: var(--blue);
  max-width: 100vw;
}

.administrators-only-section {
  min-height: 60vh;
  display: flex;
  align-items: center;
}

.blog-post-info-desktop {
  display: none;
}

/* ADMIN STYLES */
.back-to-admin {
  text-align: center;
}

.back-to-admin h3 {
  margin: 0;
  padding: 25px;
}

.back-to-admin a {
  text-decoration: none;
  color: var(--blue);
}

.back-to-admin a:hover {
  color: var(--orange);
}

.admin-post-buttons {
  text-align: center;
}

.delete-button {
  border: 1px solid var(--blue);
  background-color: var(--orange);
  color: var(--blue);
  width: 150px;
  height: 50px;
}

.delete-button:hover {
  border: 1px solid var(--orange);
  background-color: var(--blue);
  color: var(--orange);
  cursor: pointer;
}

.last-updated-span {
  font-size: 0.9em;
  padding-left: 10px;
}

/* ADMIN MODAL STYLES */

.delete-modal-overlay {
  border: 1px solid green;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
}

.delete-modal {
  position: "absolute";
  border: 1px solid var(--blue);
  background: var(--egg1);
  overflow: "auto";
  -webkit-overflow-scrolling: "touch";
  border-radius: "4px";
  outline: "none";
  padding: "20px";
  height: 30%;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.delete-modal .delete-modal-cancel-button {
  border: 1px solid var(--blue);
  background-color: white;
  color: var(--blue);
  width: 150px;
  height: 50px;
}

.delete-modal .delete-modal-cancel-button:hover {
  border: 1px solid white;
  background-color: var(--blue);
  color: white;
  width: 150px;
  height: 50px;
  cursor: pointer;
}

.delete-modal .delete-modal-confirm-button {
  border: 1px solid var(--blue);
  background-color: var(--orange);
  color: var(--blue);
  width: 150px;
  height: 50px;
}

.delete-modal .delete-modal-confirm-button:hover {
  border: 1px solid red;
  background-color: var(--blue);
  color: red;
  cursor: pointer;
}

@media (min-width: 900px) {
  .blog-post-container-desktop {
    margin: 0 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .blog-post-image-desktop {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .blog-post-info-mobile {
    display: none;
  }
  .blog-post-info-desktop {
    display: block;
    margin-bottom: 4vh;
  }
  .blog-post-container img {
    width: 50%;
    height: auto;
  }
  .blog-post-container h1 {
    max-width: 60%;
  }
  .blog-post-info-desktop h5 {
    display: inline;
    font-weight: 300;
  }
  .post-data {
    width: 60%;
    margin: 5vh;
  }
  .last-updated-span {
    font-size: 0.9em;
    margin-bottom: 5px;
  }
}

.loader-wrapper {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  width: auto;
  background-color: var(--egg1);
}

.loader-wrapper span {
  color: var(--blue);
  margin-top: 10px;
}

.e-richtexteditor {
  /* width: 50px !important; */
}

form {
  border: 1px solid var(--blue);
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 5px;
  background-color: var(--egg1);
  min-height: 45vh;
}

form label {
  margin: 10px 0;
  /* color: var(--blue); */
}

form input {
}
form textarea {
}

form .e-richtexteditor {
  margin-top: 10px;
}

form .form-button-container {
  text-align: center;
  margin: 20px 0 10px;
}

form button {
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: white;
  width: 150px;
  height: 50px;
}

form button:hover {
  background-color: white;
  color: var(--blue);
  cursor: pointer;
}

.form-error {
  color: red;
  font-size: 0.7rem;
}

/* Components on Form Pages */

#form-cancel-button {
  border: 1px solid var(--blue);
  background-color: var(--orange);
  color: var(--blue);
  width: 150px;
  height: 50px;
  margin: 15px 0;
}
#form-cancel-button:hover {
  background-color: var(--blue);
  border: 1px solid var(--orange);
  color: var(--orange);
  cursor: pointer;
}

.cancel-post {
  text-align: center;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

:root {
  /* --blue: #113e3e; */
  --blue: #06181e;
  --lightblue: #055252;
  --orange: #fcb334;
  --yellow: #ebf094;
  --beige: #ecedda;
  --darkegg: #e0d3cc;
  --egg1: #f2efeb;
  --egg2: #f2efea;
  --offwhite: #fffdf5;

  --for-phone-only: "max-width: 599px";
  --for-tablet-portrait-up: "min-width 600px";
  --for-tablet-landscape-up: "min-width 900px";
  --for-desktop-up: "min-width 1200px";
  --for-big-desktop-up: "min-width 1800px";
}

body,
html {
  font-family: "Montserrat", sans-serif;
}


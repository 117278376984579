.contact-section-mobile {
  /* margin: 0 0 15% 0; */
  padding: 0 5% 15%;
  text-align: center;
  background: var(--egg1);
}
.contact-section-mobile .contact-me-container {
  display: inline-block;
}
.contact-section-mobile h1 {
  color: var(--blue);
  font-family: "Shrikhand", cursive;
}
.contact-section-mobile form {
  /* margin: 0;
  padding: 0 5%; */
  margin: 7% 0 0 0;
  padding: 0 5%;
  background-color: var(--blue);
  color: var(--orange);
  text-align: left;
  /* background-color: var(--orange); */
}
.contact-section-mobile input {
  border: 1px solid var(--blue);
  line-height: 2;
  background: var(--egg1);
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: var(--blue);
}
.contact-section-mobile label {
  font-weight: bold;
}
.contact-section-mobile input:focus,
.contact-section-mobile textarea:focus {
  outline: none !important;
  border-color: var(--orange);
  box-shadow: 0 0 8px var(--orange);
}
.contact-section-mobile input:hover,
.contact-section-mobile textarea:hover {
  outline: none !important;
  border-color: var(--orange);
}
.contact-section-mobile textarea {
  border: 1px solid var(--blue);
  height: 150px;
  resize: none;
  background: var(--egg1);
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: var(--blue);
}
.contact-section-mobile button {
  margin-top: 50px;
  margin-bottom: 50px;
  border-color: var(--orange);
  color: var(--orange);
  font-weight: bold;
}
.contact-section-mobile button:hover {
  color: var(--blue);
  background-color: var(--orange);
  box-shadow: 0 0 10px var(--orange);
}

@media (min-width: 900px) {
  .contact-section-mobile form {
    max-width: 50%;
    margin: auto;
  }
}

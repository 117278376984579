/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

:root {
  /* --blue: #113e3e; */
  --blue: #06181e;
  --lightblue: #055252;
  --orange: #fcb334;
  --yellow: #ebf094;
  --beige: #ecedda;
  --darkegg: #e0d3cc;
  --egg1: #f2efeb;
  --egg2: #f2efea;
  --offwhite: #fffdf5;

  --for-phone-only: "max-width: 599px";
  --for-tablet-portrait-up: "min-width 600px";
  --for-tablet-landscape-up: "min-width 900px";
  --for-desktop-up: "min-width 1200px";
  --for-big-desktop-up: "min-width 1800px";
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Shrikhand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shrikhand&display=swap");

body,
html {
  font-family: "Montserrat", sans-serif;
}

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-icons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css";

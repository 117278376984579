.blog-post-container {
  background-color: var(--egg1);
}

.blog-post-container img {
  width: 100%;
  height: auto;
}

.blog-post-container h1 {
  margin: 0;
  padding: 30px 10px;
  font-family: "Shrikhand", cursive;
}

.blog-post-container h5 {
  padding: 0 10px;
  color: var(--blue);
  font-family: "Shrikhand", cursive;
  font-weight: 100;
}

.blog-post-container a {
  text-decoration: none;
  color: var(--blue);
}

.blog-post-container a:hover {
  color: var(--orange);
}

.blog-post-container .post-data {
  padding: 0 10px;
  padding-bottom: 30px;
  color: var(--blue);
  max-width: 100vw;
}

.administrators-only-section {
  min-height: 60vh;
  display: flex;
  align-items: center;
}

.blog-post-info-desktop {
  display: none;
}

/* ADMIN STYLES */
.back-to-admin {
  text-align: center;
}

.back-to-admin h3 {
  margin: 0;
  padding: 25px;
}

.back-to-admin a {
  text-decoration: none;
  color: var(--blue);
}

.back-to-admin a:hover {
  color: var(--orange);
}

.admin-post-buttons {
  text-align: center;
}

.delete-button {
  border: 1px solid var(--blue);
  background-color: var(--orange);
  color: var(--blue);
  width: 150px;
  height: 50px;
}

.delete-button:hover {
  border: 1px solid var(--orange);
  background-color: var(--blue);
  color: var(--orange);
  cursor: pointer;
}

.last-updated-span {
  font-size: 0.9em;
  padding-left: 10px;
}

/* ADMIN MODAL STYLES */

.delete-modal-overlay {
  border: 1px solid green;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
}

.delete-modal {
  position: "absolute";
  border: 1px solid var(--blue);
  background: var(--egg1);
  overflow: "auto";
  -webkit-overflow-scrolling: "touch";
  border-radius: "4px";
  outline: "none";
  padding: "20px";
  height: 30%;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.delete-modal .delete-modal-cancel-button {
  border: 1px solid var(--blue);
  background-color: white;
  color: var(--blue);
  width: 150px;
  height: 50px;
}

.delete-modal .delete-modal-cancel-button:hover {
  border: 1px solid white;
  background-color: var(--blue);
  color: white;
  width: 150px;
  height: 50px;
  cursor: pointer;
}

.delete-modal .delete-modal-confirm-button {
  border: 1px solid var(--blue);
  background-color: var(--orange);
  color: var(--blue);
  width: 150px;
  height: 50px;
}

.delete-modal .delete-modal-confirm-button:hover {
  border: 1px solid red;
  background-color: var(--blue);
  color: red;
  cursor: pointer;
}

@media (min-width: 900px) {
  .blog-post-container-desktop {
    margin: 0 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .blog-post-image-desktop {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .blog-post-info-mobile {
    display: none;
  }
  .blog-post-info-desktop {
    display: block;
    margin-bottom: 4vh;
  }
  .blog-post-container img {
    width: 50%;
    height: auto;
  }
  .blog-post-container h1 {
    max-width: 60%;
  }
  .blog-post-info-desktop h5 {
    display: inline;
    font-weight: 300;
  }
  .post-data {
    width: 60%;
    margin: 5vh;
  }
  .last-updated-span {
    font-size: 0.9em;
    margin-bottom: 5px;
  }
}

/* @keyframes animatedBackground {
  from {
    background-position: 100% 0;
  }
  to {
    background-position: 0 0;
  }
} */

.intro-container {
  /* border-top: 1px solid #06181e;
  background-image: url("../img/earf.png");
  min-height: 25vh; */
  /* background-attachment: fixed;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: contain; */
  /* text-align: top center; */
  /* margin: auto;
  width: 100vw; */
}

.home-container {
  background-color: var(--egg1);
}

.intro-paragraph {
  width: 100%;
  max-width: 100vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0 0;
}

.intro-paragraph p {
  /* padding: 20px 5% 0; */
  color: #06181e;
  padding: 0 5%;
}

.mission-statement {
  font-family: "Shrikhand", cursive;
  font-weight: lighter;
  color: var(--orange);
}

h1 {
  color: #06181e;
  text-align: center;
  font-weight: 100;
}

.recent-posts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recent-posts h1 {
  font-family: "Shrikhand", cursive;
}

.display-more-posts {
  text-align: center;
}

.display-more-posts span {
  font-size: 0.75rem;
  color: #06181e;
}

.display-more-posts span:hover {
  cursor: pointer;
  color: var(--orange);
}

.line-break {
  width: 90%;
  border-bottom: 6px solid var(--darkegg);
  margin-top: 10px;
}

.about-container {
  padding: 5%;
}

.about-container .line-break {
  width: 100%;
}

.about-container h1 {
  font-family: "Shrikhand", cursive;
}

.about-container img {
  width: 100%;
  height: auto;
}

.about-container p {
  text-align: center;
}

.insta-section {
  padding: 0 5%;
}

.insta-section h1 {
  font-family: "Shrikhand", cursive;
}

.insta-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.insta-title-container .line-break {
  width: 88vw;
  margin-bottom: 10px;
}

.picture-section {
  padding: 7px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.picture-section img {
  object-fit: cover;
  object-position: 50% 50%;
  width: 88vw;
  height: auto;
  max-height: 88vw;
  /* margin: 2% 0; */
  padding: 2% 0;
  /* box-shadow: 0 0 2px var(--blue); */
}

.box {
  width: 300px;
  height: 300px;
  /* padding-top: 300px; */
  background-color: black;
  margin-bottom: 7px;
}

/* ADMIN STYLES */

.admin-button {
  border: 1px solid var(--blue);
  background-color: white;
  color: var(--blue);
  width: 150px;
  height: 50px;
}

.admin-button:hover {
  background-color: var(--blue);
  color: white;
  cursor: pointer;
}

.admin-title {
  margin: 0;
  padding-top: 20px;
}

.admin-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.admin-buttons button {
  margin-bottom: 10px;
}

.home-container-desktop {
  display: none;
}

.about-contact-desktop {
  display: none;
}

.contact-form-error {
  color: red;
  font-size: 0.8rem;
  font-weight: bold;
}
.contact-form-submitted {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 59vh;
}
.contact-form-sending {
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 10px;
  line-height: 3.1rem;
  font-size: 1rem;
}
.contact-form-thank {
  color: var(--egg1);
}

.contact-form-thank-message {
  color: var(--egg1);
  font-size: 0.9rem;
}
@media (min-width: 900px) {
  /* .home-container-mobile {
    display: none;
  }

  .home-container-desktop {
    display: flex;
    padding: 0 5vw;
  }

  .right-panel {
    width: 85%;
    border-left: 1px solid var(--blue);
  } */
  .home-container-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .intro-paragraph p {
    /* padding: 20px 5% 0; */
    color: #06181e;
    padding: 0 5%;
  }
  .short-intro {
    font-size: 1.1rem;
  }

  .mission-statement {
    font-family: "Shrikhand", cursive;
    font-weight: lighter;
    color: var(--orange);
    font-size: 1.2rem;
  }
  .recent-posts {
    margin-top: 5vh;
  }
  .line-break {
    width: 100%;
  }
  .home-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 3% 5% 5%;
  }
  .about-container {
    display: none;
  }
  .insta-section {
    display: flex;
    padding: 5% 5% 5% 5%;
  }

  .insta-content {
    /* width: 67%; */
    text-align: center;
  }
  .insta-title-container {
    display: inline-block;
    margin-bottom: 5%;
  }
  .insta-title-container .line-break {
    width: 100%;
  }
  .picture-section {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-content: space-between;
  }
  .picture-section img {
    object-fit: cover;
    width: 20vw;
    height: 20vw;
    margin: 1% 0;
  }
  .about-author-container .line-break {
    margin-bottom: 45px;
  }
  .about-contact-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 40px;
    padding: 0 5%;
  }
  .about-container-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 75%; */
    /* margin: 0 5%; */
  }

  .about-container-desktop h1 {
    color: var(--blue);
    font-family: "Shrikhand", cursive;
  }
  .about-desktop-content {
    display: flex;
  }
  .about-container-desktop img {
    /* width: 70%;
    margin-top: 7%; */
    margin: 0 7px 7px 0;
    object-fit: cover;
    object-position: 50% 50%;
    height: 75vh;
    width: 75vh;
  }
  .about-desktop-paragraphs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.2rem;
    padding: 3%;
  }
  .about-desktop-paragraphs p {
    color: var(--blue);
  }
  .contact-section {
    width: 60%;
    margin: 0;
    padding: 5% 5%;
    background-color: var(--egg1);
    text-align: center;
  }
  .contact-section .contact-me-container {
    display: inline-block;
  }
  .contact-section h1 {
    color: var(--blue);
    font-family: "Shrikhand", cursive;
  }
  .contact-section form {
    margin: 7% 0 0 0;
    padding: 0 5%;
    background-color: var(--blue);
    color: var(--orange);
    text-align: left;
    height: 65vh;
    display: flex;
    justify-content: center;
  }
  .contact-section label {
    font-weight: bold;
  }
  .contact-section input {
    border: 1px solid var(--blue);
    line-height: 2;
    background: var(--egg1);
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    color: var(--blue);
  }
  .contact-section input:focus,
  .contact-section textarea:focus {
    outline: none !important;
    border-color: var(--orange);
    box-shadow: 0 0 8px var(--orange);
  }
  .contact-section input:hover,
  .contact-section textarea:hover {
    outline: none !important;
    border-color: var(--orange);
  }
  .contact-section textarea {
    border: 1px solid var(--blue);
    height: 150px;
    resize: none;
    background: var(--egg1);
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    color: var(--blue);
  }
  .contact-section button {
    margin-top: 50px;
    margin-bottom: 50px;
    border-color: var(--orange);
    color: var(--orange);
    font-weight: bold;
  }
  .contact-section button:hover {
    /* border-color: var(--blue); */
    color: var(--blue);
    background-color: var(--orange);
    box-shadow: 0 0 10px var(--orange);
  }
}

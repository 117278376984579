#login-button {
  border: 1px solid var(--blue);
  background-color: white;
  color: var(--blue);
  width: 150px;
  height: 50px;
}

#login-button:hover {
  background-color: var(--blue);
  color: white;
  cursor: pointer;
}

#logout-button {
  border: 1px solid var(--orange);
  background-color: var(--blue);
  color: var(--orange);
  width: 150px;
  height: 50px;
}

#logout-button:hover {
  background-color: var(--orange);
  border: 1px solid var(--blue);
  color: var(--blue);
  cursor: pointer;
}

.admin-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
}

.admin-info-container span {
  color: red;
  font-size: 0.75rem;
}

.admin-info-container button {
  width: 200px;
}

.admin-info-container button:hover {
  cursor: pointer;
}

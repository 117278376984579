.navmenuContainer {
  background-color: #06181e;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  /* transition: transform 0.6s cubic-bezier(0.52, 0, 0, 1); */
  overflow: scroll;
  z-index: 1000;
  justify-content: center;
  overflow: hidden;
}

.page-links {
  display: flex;
  flex-direction: column;
  height: 50%;
  padding: 5%;
}

.navmenuContainer.hiding {
  /* transition: opacity 1s ease-out; */
  opacity: 0;
  transform: translate3d(-100vw, 0, 0);
  transition: opacity 0.2s ease-out, transform 0s 0.2s;
}

.navmenuContainer.showing {
  /* opacity: 1; */
  transform: translate3d(0vw, 0, 0);
  transition: transform 0.6s cubic-bezier(0.52, 0, 0, 1);
  overflow: hidden;
}

.closeButton {
  position: fixed;
  top: 0;
  right: 0;
  color: #f2efeb;
  margin: 10px 20px;
}

.closeButton:hover {
  cursor: pointer;
}

.navmenuContainer a {
  color: #f2efeb;
  text-decoration: none;
  font-size: 2rem;
  margin: 0.2rem 0;
}

.active {
  color: var(--orange);
}

.hidden {
  display: none;
}

.nav-menu-item:hover {
  color: var(--orange);
}

@media (min-width: 900px) {
  .navmenuContainer {
    display: none;
  }
}

.footer-container {
  background-color: #06181e;
  padding: 10px;
  min-height: 9vh;
}

.footer-content h4 {
  margin-bottom: 15px;
  color: var(--egg1);
}

.footer-text {
  color: var(--egg1);
  text-align: center;
  margin: 0;
}

.footer-link {
  color: var(--orange);
  text-decoration: none;
  text-align: center;
  margin: 0;
}

.footer-link:hover {
  color: var(--orange);
  text-decoration: underline;
  text-align: center;
  margin: 0;
}

.footer-interactive {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-form {
  display: flex;
  flex-direction: column;
  width: 270px;
}

.footer-form input {
  text-align: center;
  margin-bottom: 15px;
}

.footer-form button {
  background-color: #06181e;
  border: 1px solid white;
  color: white;
  margin-bottom: 15px;
}

.footer-form button:hover {
  background-color: white;
  border: 1px solid #06181e;
  color: #06181e;
  margin-bottom: 15px;
}

.footer-social-icons {
  min-width: 270px;
  max-width: 100vw;
  color: var(--egg1);
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.footer-copyright {
  margin-top: 4vh;
}

.footer-copyright span {
  display: block;
  font-size: 12px;
  color: var(--egg1);
}

.social-icon {
  width: 10%;
}

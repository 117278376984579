.category-page {
  background-color: var(--egg1);
  padding-bottom: 20px;
}

.category-header {
  padding: 5% 5% 2% 5%;
}

.category-title {
  font-family: "Shrikhand", cursive;
}

.category-line-break {
  border-bottom: 6px solid var(--darkegg);
  margin-top: 10px;
}

@media (min-width: 900px) {
  .category-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5%;
  }
}

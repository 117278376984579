.scroll-arrow-container {
  background-color: var(--egg1);
  padding-right: 5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.arrow-and-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.scroll-up-arrow {
  color: var(--blue);
  background-color: var(--egg1);
  position: relative;
  right: 35px;
}

.scroll-up-text {
  color: var(--blue);
  position: relative;
  right: 3px;
  bottom: 10px;
}

.arrow-and-text-container:hover {
  cursor: pointer;
}

.arrow-and-text-container:hover * {
  color: var(--orange);
  cursor: pointer;
}
